import { oneLine } from 'common-tags';
import { IRule, IRulesMediaCard } from 'interface';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PopupVideo } from '../molecules';
const baseUrl = 'https://storage.googleapis.com/engage-transcoded-videos/';
const baseUrlUploaded = 'https://storage.googleapis.com/engage-uploaded-videos/';

type IProps = {
  ready: boolean;
  uuid: string;
  rules: IRulesMediaCard[];
};

const FormatMediaCard = ({ rules, uuid, ready }: IProps) => {
  const intl = useIntl();

  const [previewVideo, setPreviewVideo] = React.useState<boolean>(false);
  const [src, setSrc] = React.useState<string>('/images/draft-dark.png');

  const handleClick = () => {
    setPreviewVideo(true);
  };

  const handleClose = () => {
    setPreviewVideo(false);
  };

  useEffect(() => {
    if (ready) {
      setSrc(`${baseUrl}${uuid}_thumb.jpg`);
    }
  });
  return (
    <div className={oneLine`w-full bg-white dark:bg-dark-200 p-2 rounded space-y-2 relative`}>
      {/* {previewVideo && (
        <PopupVideo
          closePopup={() => {
            setPreviewVideo(false);
          }}
          key="1"
          preview={`${baseUrl}${uuid}_preview.mp4`}
        />
      )} */}

      {/* <div className="relative">
        <div
          className="relative w-full cursor-pointer"
          onClick={() => {
            setPreviewVideo(true);
          }}
        >
          <img src={src} className={oneLine`w-full rounded-3xl h-56 object-cover  object-center mx-auto`} />
        </div>
      </div> */}

      <div aria-describedby={uuid} onClick={handleClick} className={`relative ${previewVideo ? 'active' : ''}`}>
        <div className="relative w-full cursor-pointer">
          <img src={src} className={oneLine`w-full rounded-3xl h-56 object-cover  object-center mx-auto`} />
        </div>
      </div>

      
        {previewVideo && (
          <PopupVideo
            closePopup={() => {
              setPreviewVideo(false);
            }}
            key="1"
            preview={`${baseUrl}${uuid}_preview.mp4`}
          />
        )}

      {/* {rules.length === 0 ? (
        <>
          <h3 className="p-0 m-0 text-xl font-semibold text-primary-400">Default Visual</h3>
          <p className="p-0 m-0 text-sm font-light text-primary-400">This visual will be cycled when no rules are met</p>
        </>
      ) : (
        <>
          <h3 className="p-0 m-0 text-xl font-semibold text-primary-400">Contextual video</h3>
          <p className="p-0 m-0 text-sm font-light text-primary-400">This visual will play if ANY of the triggers are met.</p>
        </>
      )}

      <>
        <div className="flex flex-wrap space-y-1">
          {rules.map((el, index) => {
            return (
              <div className="flex items-center mr-1">
                {index !== 0 && (
                  <RuleChip
                    key={`or_${index}`}
                    type="add"
                    label="OR"
                    onClick={(ev: IRule) => {
                      //handleToggle(ev)
                    }}
                  />
                )}
                <RuleChip
                  key={index}
                  endIcon="akar-icons:circle-plus"
                  type={el.type}
                  label={el.label}
                  onClick={(ev: IRule) => {
                    //handleToggle(ev)
                  }}
                />
              </div>
            )
          })}
        </div>
      </> */}
    </div>
  );
};
export default FormatMediaCard;
