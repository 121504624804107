import { DateTime } from 'luxon';
import { CustomDate } from '../context/monday/monday.type';
export type OutputType = 'unix' | 'formatted';

const calculateStartEndDate = (duration: string) => {
  const now = DateTime.utc();
  const thisMonday = now.startOf('week');
  const twoWeeksFromThisMonday = thisMonday.plus({ weeks: 2 });
  const startDateUnix = twoWeeksFromThisMonday.toSeconds();
  const endDateUnix = twoWeeksFromThisMonday?.plus({ days: parseInt(duration, 10) - 1 }).toSeconds();

  return { startDateUnix, endDateUnix };
};

const calculateSlots = (startDate: number, endDate: number, perDay: number) => {
  const start = DateTime.fromSeconds(startDate, { zone: 'utc' });
  const end = DateTime.fromSeconds(endDate, { zone: 'utc' }).endOf('day');

  let slots = [];

  let current = start.startOf('day');
  while (current <= end) {
    for (let i = 0; i < perDay; i++) {
      const slotStart = current.plus({ hours: i });

      slots.push(`${slotStart.toFormat('cccc')}_${slotStart.hour}`);
    }
    current = current.plus({ days: 1 });
  }
  return slots;
};

const matchesDayOfWeek = (date: DateTime, dayOfWeek: string): boolean => {
  switch (dayOfWeek) {
    case 'Every Day':
      return true;
    case 'Every Monday':
      return date.weekday === 1;
    case 'Every Tuesday':
      return date.weekday === 2;
    case 'Every Wednesday':
      return date.weekday === 3;
    case 'Every Thursday':
      return date.weekday === 4;
    case 'Every Friday':
      return date.weekday === 5;
    case 'Every Saturday':
      return date.weekday === 6;
    case 'Every Sunday':
      return date.weekday === 7;
    case 'Weekdays':
      return date.weekday >= 1 && date.weekday <= 5;
    case 'Weekends':
      return date.weekday === 6 || date.weekday === 7;
    default:
      return false;
  }
};

type Slot = number | string;

const createSlot = (time: DateTime, dayOfWeek: string, outputType: OutputType): Slot => {
  switch (outputType) {
    case 'unix':
      return time.toSeconds();
    case 'formatted':
      return `${dayOfWeek.toUpperCase()}_${time.hour}`;
    default:
      throw new Error('Invalid output type specified');
  }
};

const processDay = (current: DateTime, whenItem: CustomDate, slots: Slot[], outputType: OutputType) => {
  const { dayOfWeek, startHour, endHour } = whenItem;
  if (matchesDayOfWeek(current, dayOfWeek)) {
    console.log('BBB,', (startHour || '00:00').split(':'));

    let startTime = current.set({
      hour: parseInt((startHour || '00:00').split(':')?.[0] || '00', 10),
      minute: 0,
    });
    const endTime = current.set({
      hour: parseInt((endHour || '23:00').split(':')?.[0] || '23', 10),
      minute: 0,
    });

    while (startTime < endTime) {
      slots.push(createSlot(startTime, dayOfWeek, outputType));
      startTime = startTime.plus({ hours: 1 });
    }
  }
};

const createSlots = (startDate: number, endDate: number, when: CustomDate[], outputType: OutputType): Slot[] => {
  const start = DateTime.fromSeconds(startDate, { zone: 'utc' });
  const end = DateTime.fromSeconds(endDate, { zone: 'utc' }).endOf('day');

  let slots: Slot[] = [];
  let current = start.startOf('day');

  while (current <= end) {
    // eslint-disable-next-line no-loop-func
    when.forEach(whenItem => {
      console.log('whenItem', whenItem);
      processDay(current, whenItem, slots, outputType);
    });
    current = current.plus({ days: 1 });
  }
  return slots;
};
const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
  ];
  if (num < 1e3) return num;
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(i => num >= i.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};
function describePeriod(days: number) {
  const weeks = Math.floor(days / 7);
  const remainingDays = days % 7;

  // Handle months and years if needed; here we assume roughly 30 days for a month
  const months = Math.floor(days / 30);
  const daysAfterMonths = days % 30;

  if (months > 0) {
    if (daysAfterMonths === 0) {
      return months === 1 ? '1 month' : months + ' months';
    } else {
      return months === 1 ? `1 month and ${daysAfterMonths} days` : `${months} months and ${daysAfterMonths} days`;
    }
  } else if (weeks > 0) {
    if (remainingDays === 0) {
      return weeks === 1 ? '1 week' : weeks + ' weeks';
    } else {
      return weeks === 1 ? `1 week and ${remainingDays} days` : `${weeks} weeks and ${remainingDays} days`;
    }
  } else {
    return days === 1 ? '1 day' : days + ' days';
  }
}
const MondayUtils = {
  calculateStartEndDate,
  calculateSlots,
  nFormatter,
  describePeriod,
  matchesDayOfWeek,
  createSlots,
};
export default MondayUtils;
